import React, { useRef, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as Api from "../../api/fetchClient";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router";
import { Map, TileLayer, ZoomControl, Marker, Popup, CircleMarker } from "react-leaflet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import CarModels from "./CarModels";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // display: 'flex',
    // flexDirection: 'row'
  },
  leftcontainer: {
    width: 200,
    padding: 12,
  },
  container: {
    maxHeight: "100% - 64px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  submit: {
    margin: 4,
  },
});

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ruRU
);

export default function CarBrand() {
  let { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [nearMarkers, setNearMarkers] = React.useState([]);
  const [errorMessage, setError] = React.useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });

  const [dragMarker, setDragable] = React.useState(true);

  const { vertical, horizontal, open } = state;
  const history = useHistory();

//   React.useEffect(() => {
//     const fetchData = async () =>
//       await Api.GetMethod(
//         (body) => {
//           setData(body);
//           setLoading(false);
//           console.log(body);

//           Api.GetMethod(
//             (body) => setNearMarkers(body.gasstations),
//             "/gasstation/radius?longitude=" + body.longitude + "&latitude=" + body.latitude + "&distance=1",
//             (error) => {
//               setError(error);
//               setState({ ...state, open: true });
//             }
//           );
//         },
//         "/gasstationcreaterequest/" + id,
//         (error) => {
//           setError(error);
//           setState({ ...state, open: true });
//         }
//       );
//     fetchData();
//   }, [id, state]);

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={theme}>
      <div>
        <div className={classes.root}>          
          <div className={classes.flexContainer}>
            <div className={classes.leftcontainer}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Название"
                name="name"
                autoFocus
                defaultValue={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className={classes.leftcontainer}>
            </div>
            <div className={classes.leftcontainer}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                  setLoading(true);
                  Api.PutMethod(
                    (body) => {
                      history.goBack();
                    },
                    "/gasstationcreaterequest/" + data.id,
                    JSON.stringify(data),
                    (error) => {
                      setError(error);
                      setState({ ...state, open: true });
                    }
                  );
                }}
              >
                Подтвердить
              </Button>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                    history.push('/carmodel/'+id+'/create')
                }}
              >
                Добавить марку
              </Button>

              {/* <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={(e) => {
                  setLoading(true);
                  Api.DeleteMethod(
                    (body) => {
                      history.goBack();
                    },
                    "/gasstationcreaterequest/" + data.id,
                    (error) => {
                      setError(error);
                      setState({ ...state, open: true });
                    }
                  );
                }}
              >
                Удалить
              </Button> */}
            </div>
          </div>
          <CarModels />
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={() => setState({ ...state, open: false })}
        >
          <Alert onClose={() => setState({ ...state, open: false })} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}
