import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from './component/Dashboard';
import Login from './component/SignIn';
import { SnackbarProvider } from "notistack";
import { DialogProvider } from "./component/Base/Dialog";
import { PermissionProvider } from './component/Base/Permission';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { enUS } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2' },
  },
}, enUS);

const App = () => (
  <ThemeProvider theme={theme}>
  <SnackbarProvider maxSnack={3}>
  <PermissionProvider>
  <DialogProvider>
  <div className="app-routes">
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/" component={Dashboard} />
    </Switch>
  </div>
  
  </DialogProvider>
      </PermissionProvider>
    </SnackbarProvider>
    </ThemeProvider>
);

export default App;