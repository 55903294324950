import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import * as Api from '../../api/fetchClient';
import { useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'url', label: 'Url', minWidth: 170 }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100% - 64px',
  },
});

const theme = createMuiTheme({
    palette: {
      primary: { main: '#1976d2' },
    },
  }, ruRU);

export default function Customers() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [dataNew, setDataNew] = React.useState({});
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorMessage, setError] = React.useState("")
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });

  const [createOpen, setCreateOpen] = React.useState(false);
  
  const history = useHistory();
  
  const { vertical, horizontal, open } = state;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
  const fetchData = async () =>
    await Api.GetMethod((body)=> {
    setData(body.items);
    setTotal(body.total);
    setLoading(false);
    console.log(body);
  }, '/customercompany?page='+page+'&perpage='+rowsPerPage, (error)=>{
    setError(error);
    setState({ ...state, open: true });
  });
  fetchData();
}, [page, rowsPerPage, total])

  return (loading ? (
        <LinearProgress />) :

    <ThemeProvider theme={theme}>
        <Button onClick={()=>setCreateOpen(true)} variant="contained" color="primary" disableElevation>
  Create customer
</Button>
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={()=> history.push('/customer/'+row.id)}>
                  {columns.map((column) => {
                    const value = row[column.id];                  
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

            <Dialog 
            open={createOpen} 
            onClose={()=>setCreateOpen(false)} 
            aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Creation of new customer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create new customer and add contract to it
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Customer name"
            fullWidth
            onChange={e =>            
              {
              setDataNew({ ...dataNew, customerName: e.target.value })}}
          />
          <TextField
            margin="dense"
            id="contract"
            label="Customer contract name"
            fullWidth
            onChange={e =>            
              {
              setDataNew({ ...dataNew, contractName: e.target.value })}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setCreateOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>
            {
              setCreateOpen(false);
              Api.PostMethod((body)=> {
                history.goBack();
              }, '/customercompany', JSON.stringify(dataNew), (error)=>{
                setError(error);
                setState({ ...state, open: true });
              })
            }
            } color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={()=>setState({ ...state, open: false })}>
  <Alert onClose={()=>setState({ ...state, open: false })} severity="error">
    {errorMessage}
  </Alert>
</Snackbar>
    </Paper>
  </ThemeProvider>
  );
} 