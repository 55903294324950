import React, { useState, useEffect, useCallback, useRef } from "react";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";
import TableActions from "./TableActions";
import Table from "./Table";
import axios from "../../api";

//import { useLookup } from "../Lookup";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { useDialog } from "./Dialog";

const filterNullParams = (params) =>
  Object.keys(params).reduce((o, key) => {
    const item = params[key];
    return item === "" || item == null ? o : { ...o, [key]: item };
  }, {});

const defaultTableData = {
  total: 0,
  items: [],
};

const DataTable = ({ title, top, columns, url, selection, selectedIds, headerActions, onChange }) => {
  //const lookup = useLookup();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = useCallback(enqueueSnackbar, []);
  const [tableData, setTableData] = useState(defaultTableData);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [tableColumns, setTableColumns] = useState();
  const tableState = useRef();

  const onSelectionChange = useCallback(onChange, []);

  const [dialog, setDialog] = useDialog();

  const handleActionClick = useCallback(
    (action, row) => {
      setDialog({
        type: "confirm",
        open: true,
        data: {
          action,
          row,
        },
        title: action.title,
        body: typeof action.body === "function" ? action.body(row) : action.body,
      });
    },
    [setDialog]
  );

  // manual GET with table state
  // const [{ data, loading, error }, refetch] = useAxios(
  //   {
  //     url,
  //     params: filterNullParams({ page: 1, perpage: 20 }),
  //   },
  //   { manual: true }
  // );

  const [error, setError] = useState();
  const [data, setData] = useState();
  const[loading, setLoading] = useState(true);

  const fetchData = useCallback((params) => 
  {
    axios.get(url, {params: filterNullParams(params)})
    .then((response)=>{
      console.log(response);
      setData(response.data);
      setLoading(false);
    })
    .catch((error)=>{
      setError(error)});
    // executePost({data: auth}).then((response)=>{
    //   sessionStorage.setItem("novemdata.token", response.data.token);
    //   axios.defaults.headers.Authorization = response.data.token;
    //   if (response.data.permissions) {
    //     setPermission(response.data.permissions);
    //   }
    // history.push("/");
    // });
    // }
  }, [url]);
  
  //refetch({ url, params: filterNullParams(params) })

  useEffect(() => {
    if (dialog && dialog.isConfirmed) {
      dialog.data.action.onConfirm(dialog.data.row);
      if (tableState.current && dialog.data.action.shouldRefetch) {
        fetchData(filterNullParams(tableState.current));
      }
      setDialog(null);
    }
  }, [dialog, fetchData, setDialog]);

  useEffect(() => {
    function prepareColumns(columns) {
      return columns.map((column) => {
        return {
          ...column,
          Cell: !column.Cell
            ? ({ value, row }) => {
                switch (column.type) {
                  case "actions":
                    return <TableActions actions={column.actions} onClick={handleActionClick} row={row.values}></TableActions>;
                  // case "lookup":
                  //   return lookup && lookup.hasData() ? lookup.getType(column.lookupKey, value) : value;
                  case "currency":
                    return <NumberFormat value={value} displayType="text" prefix="&#8381;" decimalScale={2} thousandSeparator={true} />;
                  case "date":
                    return value ? dayjs.unix(value).format("DD.MM.YYYY HH:mm") : "";
                  case "text":
                  default:
                    return value;
                }
              }
            : column.Cell,
        };
      });
    }
    setTableColumns(prepareColumns(columns));
  }, [columns, handleActionClick]);//, lookup]);

  useEffect(() => {
    if (data) {
      console.log(data);
      setTableData(data);
      setSkipPageReset(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      snackbar((error && error.message) || `Произошла ошибка`, { variant: "error" });
    }
  }, [error, snackbar]);

  if (tableColumns) {
    return (
      <Table
        ref={tableState}
        title={title}
        columns={tableColumns}
        headerActions={headerActions}
        data={tableData.items}
        total={tableData.total}
        top={top}
        skipPageReset={skipPageReset}
        selection={selection}
        selectedIds={selectedIds}
        onChange={onSelectionChange}
        onFetchData={fetchData}
        // change loading state both for GET and actions requests (TODO)
        loading={loading}
      />
    );
  }
  return null;
};

DataTable.whyDidYouRender = false;

export default DataTable;
