import React, { useMemo } from "react";
import useAxios from "axios-hooks";
import DataTable from "../Base/DataTable";
import { useParams } from "react-router";

export default function CarModels() {
    let { id } = useParams();
  const [{ data, loading, error }, executeDelete] = useAxios({}, { manual: true });

  const headerActions = useMemo(
    () => [
      {
        type: "add",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { accessor: "modelName", Header: "Title" },
      {
        Header: "Действия",
        accessor: "id",
        type: "actions",
        actions: [
          {
            type: "edit",
          },
        ],
      },
    ],
    [executeDelete]
  );
  return <DataTable url={`admin/carmodel?brandid=${id}`} title="Марки ТС" columns={columns}></DataTable>;
}
