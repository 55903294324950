import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmDialog({ open, title = "", body = "", acceptText = "Accept", declineText = "Cancel", onClose }) {
  const handleClose = useCallback(
    (_, reason) => {
      onClose(reason !== "escapeKeyDown" && reason !== "backdropClick");
    },
    [onClose]
  );

  const handleDecline = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleAccept = useCallback(() => {
    onClose(true);
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {declineText ? (
          <Button onClick={handleDecline} color="primary">
            {declineText}
          </Button>
        ) : null}
        {acceptText ? (
          <Button onClick={handleAccept} color="primary" autoFocus>
            {acceptText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
