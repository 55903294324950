import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Divider from '@material-ui/core/Divider';
import { usePermission, Permission } from "../component/Base/Permission";
import BuildIcon from '@material-ui/icons/Build';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import CachedIcon from '@material-ui/icons/Cached';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { NavLink } from "react-router-dom";

import { useHistory } from "react-router";

const menuConfig = [
  {
    type: "link",
    to: "/dashboard",
    icon: <DashboardIcon></DashboardIcon>,
    text: "Main",
  },
  // {
  //   type: "link",
  //   to: "/billings",
  //   icon: <PeopleIcon></PeopleIcon>,
  //   text: "Биллинг",
  //   perm: Permission.Billing,
  // },
  {
    type: "link",
    to: "/dealers",
    icon: <PeopleIcon></PeopleIcon>,
    text: "Dealers",
    perm: Permission.Dealer,
  },
  {
    type: "link",
    to: "/customers",
    icon: <BarChartIcon></BarChartIcon>,
    text: "Customer Company",
    perm: Permission.Company,
  },
  {
    type: "link",
    to: "/devices",
    icon: <LayersIcon></LayersIcon>,
    text: "Интеграция",
    perm: Permission.Device,
  },
  {
    type: "link",
    to: "/accidents",
    icon: <LocationOnIcon></LocationOnIcon>,
    text: "Accidents",
    perm: Permission.Accident,
  },
  {
    type: "subheader",
    text: "Localization",
    perm: Permission.Localization,
  },
  {
    type: "link",
    to: "/localies",
    icon: <LocalShippingIcon></LocalShippingIcon>,
    text: "Localies",
    perm: Permission.Localization,
  },
  {
    type: "link",
    to: "/uilocalies",
    icon: <FormatShapesIcon></FormatShapesIcon>,
    text: "Ui localies",
    perm: Permission.Localization,
  },
  {
    type: "subheader",
    text: "SU",
    perm: Permission.Su,
  },
  {
    type: "link",
    to: "/carbrand",
    icon: <FormatShapesIcon></FormatShapesIcon>,
    text: "CarBrands",
    perm: Permission.Su,
  },
];

export default function Menu() {
  const [permission] = usePermission();
  return (
    <div>
      {menuConfig.map((config) => {
        if (permission && typeof config.perm !== "undefined" && !(config.perm in permission && permission[config.perm].canRead)) {
          return null;
        }
        if (config.type === "subheader") {
          return (
            <div key={config.text}>
              <Divider />
              <ListSubheader inset>{config.text}</ListSubheader>
            </div>
          );
        }
console.log(permission);
        return (
          <ListItem key={config.to} activeClassName="Mui-selected" exact={config.to === "/"} button component={NavLink} to={config.to}>
            <ListItemIcon>{config.icon}</ListItemIcon>
            <ListItemText primary={config.text} />
          </ListItem>
        );
      })}
    </div>
  );
}