import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import * as Api from '../../api/fetchClient';
import { useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AlarmIcon from '@material-ui/icons/Alarm';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {DropzoneArea} from 'material-ui-dropzone';
import Box from '@material-ui/core/Box';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'url', label: 'Url', minWidth: 170 }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '100% - 64px',
  },
});

const theme = createMuiTheme({
    palette: {
      primary: { main: '#1976d2' },
    },
  }, ruRU);

export default function Devices() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorMessage, setError] = React.useState("")
  const [state, setState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
  });

  const [createOpen, setCreateOpen] = React.useState(false);
  const [files, setFiles] = React.useState(false);
  
  const history = useHistory();
  
  const { vertical, horizontal, open } = state;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
  const fetchData = async () =>
    await Api.GetMethod((body)=> {
    setData(body.items);
    setTotal(body.total);
    setLoading(false);
    console.log(body);
  }, '/customercompany?page='+page+'&perpage='+rowsPerPage, (error)=>{
    setError(error);
    setState({ ...state, open: true });
  });
  fetchData();
}, [page, rowsPerPage, total])

  return (loading ? (
        <LinearProgress />) :

    <ThemeProvider theme={theme}>
        <Button onClick={()=>setCreateOpen(true)} variant="contained" color="primary" disableElevation>
  Upload devices
</Button>

    <Dialog 
            open={createOpen} 
            onClose={()=>setCreateOpen(false)} 
            aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload devices file</DialogTitle>
        <DialogContent>
        {/* <Box component="div" visibility= {loading ? "visible" : "hidden"}>
        <LinearProgress />
        </Box> */}
        <DropzoneArea
  onChange={(files) => setFiles(files)}
/>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setCreateOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={e=>
              {
                const formData  = new FormData();
                formData.append('file', files[0])
                setCreateOpen(false)
                setLoading(true);
                Api.PostFormMethod((body)=> {
                  history.goBack();
                }, '/admin/device/upload', formData, (error)=>{
                    setLoading(false);
                  setError(error);
                  setState({ ...state, open: true });
                })
              }} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={()=>setState({ ...state, open: false })}>
  <Alert onClose={()=>setState({ ...state, open: false })} severity="error">
    {errorMessage}
  </Alert>
</Snackbar>

  </ThemeProvider>
  );
} 