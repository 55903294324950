import React, { useMemo } from "react";
import useAxios from "axios-hooks";
import DataTable from "../Base/DataTable";
import { useParams } from "react-router";

export default function UiLocales() {
    let { id } = useParams();
  const [{ data, loading, error }, executeDelete] = useAxios({}, { manual: true });

  const headerActions = useMemo(
    () => [
      {
        type: "add",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { accessor: "uiLocaleKey", Header: "uiLocaleKey" }
    ],
    [executeDelete]
  );
  return <DataTable url={`localization`} headerActions={headerActions} title="Ui Localies" columns={columns}></DataTable>;
}
