import React, { useCallback } from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory, useLocation } from "react-router-dom";
import ShareIcon from "@material-ui/icons/Share";
import OpenInNew from "@material-ui/icons/OpenInNew"
import ListIcon from "@material-ui/icons/List"

import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}));

const makeUrl = (path, prefix, param, postfix) => {
  return [path, prefix, param, postfix].filter(Boolean).join("/");
};

export default function TableActions({ actions, row, onClick }) {
  const classes = useStyles();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const onActionClick = useCallback((type, row) => () => onClick(type, row), [onClick]);
  return (
    <div className={classes.root}>
      {actions &&
        actions.map((action) => {
          const { icon: Icon, disabled, type, name } = action;
          const url = makeUrl(pathname, action.pathnamePrefix, (row && row.id) || action.pathnameParam || "create", action.pathnamePostfix);
          const disabledFn = typeof disabled === "function" ? disabled : false;
          if (type === "add") {
            return (
              <Tooltip key={type} title="Создать">
                <span>
                  <IconButton onClick={() => push(url)} disabled={disabledFn && disabledFn(row)} aria-label="Создать">
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          }
          if (type === "delete") {
            return (
              <Tooltip key={type} title="Удалить">
                <span>
                  <IconButton onClick={onActionClick(action, row)} disabled={disabledFn && disabledFn(row)} aria-label="Удалить">
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          }
          if (type === "share") {
            return (
              <Tooltip key={type} title="Log in">
                <span>
                  <IconButton onClick={onActionClick(action, row)} disabled={disabledFn && disabledFn(row)} aria-label="Log in">
                    <OpenInNew />
                  </IconButton>
                </span>
              </Tooltip>
            );
          }
          if (type === "edit") {
            return (
              <Tooltip key={type} title="Редактировать">
                <span>
                  <IconButton onClick={() => push(url)} disabled={disabledFn && disabledFn(row)} aria-label="Редактировать">
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          }
          if (type === "show") {
            return (
              <Tooltip key={type} title="Show more">
                <span>
                  <IconButton onClick={() => push(url)} disabled={disabledFn && disabledFn(row)} aria-label="Show more">
                    <ListIcon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          }
          return (
            <Tooltip key={type} title={name}>
              <span>
                <IconButton onClick={onActionClick(action, row)} disabled={disabledFn && disabledFn(row)} aria-label={name}>
                  <Icon></Icon>
                </IconButton>
              </span>
            </Tooltip>
          );
        })}
    </div>
  );
}
