import React, { useContext, useState, useCallback } from "react";
import ConfirmDialog from "./ConfirmDialog";

const DialogState = React.createContext();
const DialogDispatch = React.createContext();

export function DialogValueProvider({ children }) {
  const [state, setState] = useState();
  return (
    <DialogState.Provider value={state}>
      <DialogDispatch.Provider value={setState}>{children}</DialogDispatch.Provider>
    </DialogState.Provider>
  );
}

export function useDialogState() {
  return useContext(DialogState);
}

export function useDialogDispatch() {
  return useContext(DialogDispatch);
}

export function useDialog() {
  return [useDialogState(), useDialogDispatch()];
}

function ConcreteDialog() {
  const [dialogState, setDialogState] = useDialog();

  const onClose = useCallback(
    (isConfirmed) => {
      setDialogState({ ...dialogState, open: false, isConfirmed });
    },
    [dialogState, setDialogState]
  );

  if (dialogState && dialogState.open && dialogState.type === "confirm") {
    return <ConfirmDialog {...dialogState} onClose={onClose}></ConfirmDialog>;
  }
  // Here other dialogs
  //

  return null;
}

export function DialogProvider({ children }) {
  return (
    <DialogValueProvider>
      {children}
      <ConcreteDialog></ConcreteDialog>
    </DialogValueProvider>
  );
}
