export const url = process.env.REACT_APP_API_BASE_URL;
//export const url = "https://api.comet.novemdata.io/api";
//export const url = "http://192.168.0.109:50502/api/";
//export const url = "http://localhost:50502/api/";

export async function GetMethod(callbackOnSuccess, route, callbackOnError){

    const apiUrl = url+route
    fetch(apiUrl, { headers: {'Authorization': sessionStorage.getItem("novemdata.token")} })
    .then(handleErrors)
      .then((response) => response.json())
      .then((data) => callbackOnSuccess(data))
      .catch((error) =>{
          console.log(error);
          error.json().then((body) => callbackOnError(body.message))
        })
}

export async function DeleteMethod(callbackOnSuccess, route, callbackOnError){
    const apiUrl = url+route
    fetch(apiUrl, { method: 'DELETE', headers: { 'Authorization': sessionStorage.getItem("novemdata.token")} })
    .then(handleErrors)
      .then((response) => response.json())
      .then((data) => callbackOnSuccess(data))
      .catch((error) =>{
          console.log(error);
          error.json().then((body) => callbackOnError(body.message))
        })
}

export async function PostMethod(callbackOnSuccess, route, body, callbackOnError){
    const apiUrl = url+route
    fetch(apiUrl, { method: 'POST', body: body, headers: {'Authorization': sessionStorage.getItem("novemdata.token"), 'Content-Type': 'application/json'} })
    .then(handleErrors)
      .then((response) => response.json())
      .then((data) => callbackOnSuccess(data))
      .catch((error) =>{
          console.log(error);
          error.json().then((body) => callbackOnError(body.message))
        })
}

export async function PostFormMethod(callbackOnSuccess, route, body, callbackOnError){
  const apiUrl = url+route
  fetch(apiUrl, { method: 'POST', body: body, headers: {'Authorization': sessionStorage.getItem("novemdata.token")} })
  .then(handleErrors)
    .then((response) => response.json())
    .then((data) => callbackOnSuccess(data))
    .catch((error) =>{
        console.log(error);
        error.json().then((body) => callbackOnError(body.message))
      })
}

export async function PutMethod(callbackOnSuccess, route, body, callbackOnError){
  const apiUrl = url+route
  fetch(apiUrl, { method: 'PUT', body: body, headers: {'Authorization': sessionStorage.getItem("novemdata.token"), 'Content-Type': 'application/json'} })
  .then(handleErrors)
    .then((response) => response.json())
    .then((data) => callbackOnSuccess(data))
    .catch((error) =>{
        console.log(error);
        error.json().then((body) => callbackOnError(body.message))
      })
}

export async function PostAuthMethod(callbackOnSuccess, route, body, callbackOnError){
  var xhr = new XMLHttpRequest();
         
  xhr.open("post", url+route, true);
  xhr.setRequestHeader("Content-type", "application/json");
  xhr.onload = function () {
      if (xhr.status === 200) {
          callbackOnSuccess(JSON.parse(xhr.responseText));
      }
      else
      {
          callbackOnError(JSON.stringify(xhr.responseText));
      }
  };
  xhr.send(body);
}

function handleErrors(response) {
    if (!response.ok) {
        throw response;
    }
    return response;
}