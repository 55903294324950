import React, { useMemo } from "react";
import useAxios from "axios-hooks";
import DataTable from "../Base/DataTable";

export default function CarBrands() {
  const [{ data, loading, error }, executeDelete] = useAxios({}, { manual: true });

  const headerActions = useMemo(
    () => [
      {
        type: "add",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      //{ accessor: "id", Header: "Id" },
      { accessor: "title", Header: "Title" },
      {
        Header: "Действия",
        accessor: "id",
        type: "actions",
        actions: [
          {
            type: "edit",
          },
        ],
      },
      // {
      //   Header: "Действия",
      //   accessor: "id",
      //   type: "actions",
      //   actions: [
      //     {
      //       type: "edit",
      //     },
      //     {
      //       type: "delete",
      //       shouldRefetch: true,
      //       title: "Удаление заправки",
      //       body: (row) => `Вы уверены, что хотите удалить заправку ${row.name}?`,
      //       onConfirm: (row) => executeDelete({ url: `gasstation/${row.id}`, method: "DELETE" }),
      //     },
      //   ],
      // },
    ],
    [executeDelete]
  );
  return <DataTable url="admin/carbrand" headerActions={headerActions} title="Марки ТС" columns={columns}></DataTable>;
}
