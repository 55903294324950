import Axios from "axios";
import { configure } from "axios-hooks";
// import LRU from 'lru-cache'

export function createApi(url = "", token = "", cache = false) {
  const axios = Axios.create({
    baseURL: url,
    headers: {
      Authorization: token,
    },
  });

  configure({ axios, cache });
  return axios;
}

// Cache is disabled for now
export default createApi(process.env.REACT_APP_API_BASE_URL, sessionStorage.getItem("novemdata.token"));
