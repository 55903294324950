import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  linearContainer: {
    width: "100%",
    margin: "0 auto",
    padding: `${theme.typography.fontSize - 4}px 0`,
  },
  circularContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Loader({ children, type = "linear", loading, ...rest }) {
  const classes = useStyles();

  if (loading && type === "linear") {
    return (
      <div className={classes.linearContainer}>
        <LinearProgress {...rest} />
      </div>
    );
  }

  if (loading && type === "circular") {
    return (
      <div className={classes.circularContainer}>
        <CircularProgress {...rest} />
      </div>
    );
  }

  if (!loading) {
    return <>{children}</>;
  }
}
