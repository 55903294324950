import React, { useMemo } from "react";
import useAxios from "axios-hooks";
import DataTable from "../Base/DataTable";
import { useParams } from "react-router";


export default function Dealers() {
  let { id } = useParams();

const headerActions = useMemo(
  () => [
    {
      type: "add",
    },
  ],
  []
);

const columns = useMemo(
  () => [
    { accessor: "name", Header: "Title" },
    {
      Header: "Action",
      accessor: "id",
      type: "actions",
      actions: [
        {
          type: "show",
        },
      ],
    },
  ]
);
return <DataTable url="dealer" title="Dealers" columns={columns}></DataTable>;
}