import React, { useEffect, useRef, forwardRef, useCallback, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import MaUiTable from "@material-ui/core/Table";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableToolbar from "./TableToolbar";
import { useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable, useAsyncDebounce } from "react-table";

import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1, 1, 0),
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    flex: "0 0 auto",
  },
  table: {
    flex: "1 1 auto",
    overflowY: "auto",
  },
  footer: {
    flex: "0 0 auto",
  },
}));

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} />
    </>
  );
});

const INITIAL_SELECTED_ROW_IDS = {};

const EnhancedTable = React.forwardRef(
  (
    { title, total, headerActions, columns, data, selection, loading, onFetchData, skipPageReset, onChange, selectedIds, top = 64 },
    ref
  ) => {
    const classes = useStyles();
    const getRowId = useCallback((row) => {
      return row.id;
    }, []);
    const {
      getTableProps,
      headerGroups,
      prepareRow,
      page,
      gotoPage,
      setPageSize,
      setGlobalFilter,
      state: { pageIndex, pageSize, sortBy, selectedRowIds, globalFilter },
    } = useTable(
      {
        columns,
        data,
        autoResetPage: !skipPageReset,
        initialState: {
          selectedRowIds: (selectedIds || []).reduce((acc, id) => ({ ...acc, [id]: true }), {}) || INITIAL_SELECTED_ROW_IDS,
          pageIndex: 0,
          globalFilter: "",
          pageSize: 20,
        },
        pageCount: total,
        manualPagination: true,
        manualSortBy: true,
        disableMultiSort: true,
        manualGlobalFilter: true,
        getRowId,
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        if (selection) {
          hooks.allColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox.  Pagination is a problem since this will select all
              // rows even though not all rows are on the current page.  The solution should
              // be server side pagination.  For one, the clients should not download all
              // rows in most cases.  The client should only download data for the current page.
              // In that case, getToggleAllRowsSelectedProps works fine.
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
        }
      }
    );

    const handleChangePage = (event, newPage) => {
      gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setPageSize(Number(event.target.value));
    };

    const stateForFetch = useMemo(() => {
      // single sorting
      const sorting = sortBy && sortBy[0];
      return {
        page: pageIndex + 1,
        perpage: pageSize,
        sortTarget: sorting && sorting.id,
        isdesc: sorting ? sorting.desc : true,
        search: globalFilter,
      };
    }, [globalFilter, pageIndex, pageSize, sortBy]);

    const onFetchDataDebounced = useAsyncDebounce(onFetchData, 200);

    const containerHeight = useMemo(() => ({ maxHeight: `calc(100vh - ${top}px)` }), [top]);

    // When these table states change, fetch new data!
    useEffect(() => {
      onFetchDataDebounced(stateForFetch);
    }, [pageIndex, pageSize, sortBy, onFetchDataDebounced, globalFilter, stateForFetch]);

    useEffect(() => {
      ref.current = stateForFetch;
    }, [stateForFetch, ref]);

    useEffect(() => {
      if (selection && onChange) {
        onChange(Object.keys(selectedRowIds));
      }
    }, [onChange, selectedRowIds, selection]);

    // Render the UI for your table
    return (
      <Paper elevation={0} className={classes.root} style={containerHeight}>
        <Loader loading={loading}></Loader>
        <TableToolbar
          className={classes.header}
          title={title}
          headerActions={headerActions}
          numSelected={Object.keys(selectedRowIds).length}
          count={total}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
        <TableContainer className={classes.table}>
          <MaUiTable stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...(column.id === "selection" ? column.getHeaderProps() : column.getHeaderProps(column.getSortByToggleProps()))}
                    >
                      {column.render("Header")}
                      {column.id !== "selection" ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? "desc" : "asc"}
                        />
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody className={classes.tableBody}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaUiTable>
        </TableContainer>
        <TablePagination
          className={classes.footer}
          rowsPerPageOptions={[10, 20, 40]}
          colSpan={3}
          count={total}
          component="div"
          rowsPerPage={pageSize}
          page={pageIndex}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    );
  }
);

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
};

EnhancedTable.whyDidYouRender = false;

export default EnhancedTable;
