import React, {  } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as Api from "../../api/fetchClient";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import DealerCustomers from "./DealerCustomers";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // display: 'flex',
    // flexDirection: 'row'
  },
  leftcontainer: {
    width: 200,
    padding: 12,
  },
  container: {
    maxHeight: "100% - 64px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  submit: {
    margin: 4,
  },
});

export default function Dealer() {
  let { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [] = React.useState([]);
  const [errorMessage, setError] = React.useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "left",
  });

  const [] = React.useState(true);

  const { vertical, horizontal, open } = state;
  const history = useHistory();

//   React.useEffect(() => {
//     const fetchData = async () =>
//       await Api.GetMethod(
//         (body) => {
//           setData(body);
//           setLoading(false);
//           console.log(body);

//           Api.GetMethod(
//             (body) => setNearMarkers(body.gasstations),
//             "/gasstation/radius?longitude=" + body.longitude + "&latitude=" + body.latitude + "&distance=1",
//             (error) => {
//               setError(error);
//               setState({ ...state, open: true });
//             }
//           );
//         },
//         "/gasstationcreaterequest/" + id,
//         (error) => {
//           setError(error);
//           setState({ ...state, open: true });
//         }
//       );
//     fetchData();
//   }, [id, state]);

  return loading ? (
    <LinearProgress />
  ) : (
      <div>
        <div className={classes.root}>          
          {/* <div className={classes.flexContainer}>
            <div className={classes.leftcontainer}>
            </div>
            <div className={classes.leftcontainer}>
            </div>
            <div className={classes.leftcontainer}>
            </div>
          </div> */}
          <DealerCustomers />
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={() => setState({ ...state, open: false })}
        >
          <Alert onClose={() => setState({ ...state, open: false })} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
  );
}