import React, { useRef, createRef, useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import * as Api from "../../api/fetchClient";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router";
import { Map, TileLayer, ZoomControl, Marker, Popup, CircleMarker } from "react-leaflet";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import useAxios from "axios-hooks";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  root: {
    width: "100%",
    // display: 'flex',
    // flexDirection: 'row'
  },
  leftcontainer: {
    width: 200,
    padding: 12,
  },
  container: {
    maxHeight: "100% - 64px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  submit: {
    margin: 4,
  },
});

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  ruRU
);

export default function Locale() {
  let { id, brandid } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const snackbar = useCallback(enqueueSnackbar, []);
  const classes = useStyles();
  const [data, setData] = React.useState({title: ""});

  const [
    { data: postResponse, loading, error },
    executePost
  ] = useAxios(
    {
      url: '/localization/locale',
      method: 'POST'
    },
    { manual: true }
  )
  const history = useHistory();

  useEffect(() => {
    if (error) {
      snackbar((error && error.message) || `Произошла ошибка`, { variant: "error" });
    }
  }, [error, snackbar]);

//   React.useEffect(() => {
//     const fetchData = async () =>
//       await Api.GetMethod(
//         (body) => {
//           setData(body);
//           setLoading(false);
//           console.log(body);

//           Api.GetMethod(
//             (body) => setNearMarkers(body.gasstations),
//             "/gasstation/radius?longitude=" + body.longitude + "&latitude=" + body.latitude + "&distance=1",
//             (error) => {
//               setError(error);
//               setState({ ...state, open: true });
//             }
//           );
//         },
//         "/gasstationcreaterequest/" + id,
//         (error) => {
//           setError(error);
//           setState({ ...state, open: true });
//         }
//       );
//     fetchData();
//   }, [id, state]);

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={theme}>
        <div className={classes.root}>          
          <div className={classes.flexContainer}>
            <div className={classes.leftcontainer}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="title"
                label="Название"
                name="title"
                autoFocus
                defaultValue={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
              />
            </div>
            <div className={classes.leftcontainer}>
            </div>
            <div className={classes.leftcontainer}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => {
                  executePost({data}).then((response) => {
                    snackbar(`Успех`, { variant: "success" })
                    history.goBack();
                  });
                }}
              >
                Подтвердить
              </Button>
            </div>
          </div>
        </div>
    </ThemeProvider>
  );
}
