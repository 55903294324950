import React, { useState, useContext, useCallback, useEffect } from "react";

const PermissionState = React.createContext();
const PermissionDispatch = React.createContext();

export function PermissionProvider({ children }) {
  const [state, setState] = useState();
  return (
    <PermissionState.Provider value={state}>
      <PermissionDispatch.Provider value={setState}>{children}</PermissionDispatch.Provider>
    </PermissionState.Provider>
  );
}

export function usePermissionState() {
  return useContext(PermissionState);
}

export function usePermissionDispatch() {
  return useContext(PermissionDispatch);
}

export const Permission = {
    Su: 0,
    Dealer: 1,
    Company: 2,
    Device: 3,
    Accident: 4,
    Account: 5,
    Localization: 6,
    Notification: 7,
    Support: 8,
    Chat: 9
};

export function usePermission() {
  const permission = usePermissionState();
  const setPermission = usePermissionDispatch();
  const preparePermission = useCallback(
    (data) => {
      const preparedData = data.reduce((acc, item) => {
        return {
          ...acc,
          [item.type]: item,
        };
      }, {});
      sessionStorage.setItem("novemdata.permissions", JSON.stringify(preparedData));
      setPermission(preparedData);
    },
    [setPermission]
  );

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("novemdata.permissions"));
    if (storedData) {
      setPermission(storedData);
    }
  }, [setPermission]);

  return [permission, preparePermission];
}
