import React, { useMemo, useEffect } from "react";
import axios from '../../api'
import DataTable from "../Base/DataTable";
import { useParams } from "react-router";

export default function DealerCustomers() {
    let { id } = useParams();
  //const [{ data, loading, error }, executeLink] = useAxios({}, { manual: true });

  // useEffect(()=>
  // {
  //     if(data){
  //       console.log(data);
  //       window.open(data.redirectUrl, "_blank")
  //     }
  // }, [data]);

  const headerActions = useMemo(
    () => [
      {
        type: "add",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      { accessor: "name", Header: "Title" },
      { accessor: "url", Header: "Url" },
      {
        Header: "Actions",
        accessor: "id",
        type: "actions",
        actions: [
          // {
          //   type: "edit",
            
          // },
          {
            type: "share",
            name: "Close",
            shouldRefetch: true,
            title: "Open in new tab",
            body: (row) => `Proceed to ${row.url} ?`,
            disabled: (row) => row.metaUserId === 0,
            onConfirm: (row) => {
              axios.get(`customercompany/novemdatalink?customerCompanyId=${row.id}`)
              .then((response)=>{
                console.log(response);
                window.open(response.data.redirectUrl, "_blank")
              })
              .catch((error)=>{
                console.log(error)});
              // executePost({data: auth}).then((response)=>{
              //   sessionStorage.setItem("novemdata.token", response.data.token);
              //   axios.defaults.headers.Authorization = response.data.token;
              //   if (response.data.permissions) {
              //     setPermission(response.data.permissions);
              //   }
              // history.push("/");
              // });
              // }
            }
            
            //executeLink({ url: `customercompany/novemdatalink?customerCompanyId=${row.id}`, method: "GET" }),
          }
        ],
      },
    ],
    []
  );
  return <DataTable url={`customercompany/bydealer?dealerid=${id}`} title="Customer Companies" columns={columns}></DataTable>;
}
